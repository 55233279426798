import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ZoomMtg } from "@zoom/meetingsdk";
import CryptoJS from 'crypto-js';
import moment from "moment";

// API Service
import { getUserProfileService } from '../../service/user.service';
import { getClassDetailService } from '../../service/class.service';

// Utility Service
import { setCookieToken, getToken } from "../../utils/AuthService";

const Class = () => {
    let { slug, encryptedToken } = useParams();

    const KJUR = require("jsrsasign");

    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();

    const [user, setUser] = useState();
    const [token, setToken] = useState();

    useEffect(() => {
        if (encryptedToken) {
            setCookieToken(CryptoJS.AES.decrypt(encryptedToken?.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '='), 'Reskills').toString(CryptoJS.enc.Utf8))
        } else {
            window.location.href = process.env.REACT_APP_MAIN_DOMAIN
        }
    }, [encryptedToken])

    useEffect(() => {
        if (getToken()) {
            setToken(getToken())
        }
        // eslint-disable-next-line
    }, [getToken()])

    /**
        * @function generateSignature 
        * @param (key, secret, meetingNumber, role)
        * @description This function is used to generate signature
    */
    const generateSignature = (key, secret, meetingNumber, role) => {
        const iat = Math.round(new Date().getTime() / 1000) - 30;
        const exp = iat + 60 * 60 * 2;
        const oHeader = { alg: "HS256", typ: "JWT" };

        const oPayload = {
            sdkKey: key,
            appKey: key,
            mn: meetingNumber,
            role: role,
            iat: iat,
            exp: exp,
            tokenExp: exp
        };

        const sHeader = JSON.stringify(oHeader);
        const sPayload = JSON.stringify(oPayload);
        const sdkJWT = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, secret);
        return sdkJWT;
    }

    /**
        * @function fetchUserProfile 
        * @param
        * @description This function is used to fetch user profile information
    */
    const fetchUserProfile = async () => {
        try {
            const result = await getUserProfileService();
            if (result?.data?.status === "success") {
                setUser(result?.data?.user)
            }
        } catch (error) {
            console.log(error)
        }
    };

    /**
        * @function fetchClassDetails 
        * @param
        * @description This function is used to fetch class details
    */
    const fetchClassDetails = async () => {
        try {
            const result = await getClassDetailService(slug, user?.id);
            if (result?.data?.class) {
                if (moment?.parseZone(new Date())?.local()?.format()?.replace(/-/g, "") < moment?.parseZone(result?.data?.class?.end_date_time)?.local()?.format()?.replace(/-/g, "")) {
                    let sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY
                    let meetingNumber = result?.data?.class?.room_link?.split("/")?.pop()?.split("?")[0]
                    let passWord = result?.data?.class?.room_link?.split("/")?.pop()?.split("=")[1]

                    document.getElementById("zmmtg-root").style.display = "block";

                    ZoomMtg.init({
                        leaveUrl: `${process.env.REACT_APP_MAIN_DOMAIN}/course/${slug}`,
                        patchJsMedia: true,
                        inviteUrlFormat: "",
                        disableInvite:true,
                        success: (success) => {
                            ZoomMtg.join({
                                signature: generateSignature(sdkKey, process.env.REACT_APP_ZOOM_SECRET_KEY, meetingNumber, 0),
                                sdkKey,
                                meetingNumber,
                                passWord,
                                userName: user?.username || "Reskills",
                                userEmail: user?.email,
                                tk: "",
                                zak: "",
                                success: (success) => {
                                    console.log(success);
                                },
                                error: (error) => {
                                    console.log(error);
                                }
                            });
                        },
                        error: (error) => {
                            console.log(error);
                        }
                    });
                } else {
                    window.location.href = `${process.env.REACT_APP_MAIN_DOMAIN}/course/${slug}`
                }
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (token) {
            fetchUserProfile()
        }
    }, [token])

    useEffect(() => {
        if (user) {
            fetchClassDetails()
        }
        // eslint-disable-next-line
    }, [user])

    return <></>
}

export default Class;
